
import Vue from 'vue'
import {
  libraryRootUrl,
  myChurchUrl,
  promotionRootUrl,
  scriptureRootUrl,
  sermonRootUrl,
  siteBroadcasterUrl,
  siteLoginUrl,
  siteSeriesUrl,
  siteSermonDateUrl,
  speakerRootUrl,
  webcastRootUrl,
} from '~/assets/ts/utils/urls'
import SiteNavLi from '~/components/site/nav/Li.vue'
import WebcastPing from '~/components/_general/WebcastPing.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { SiteNavGroup } from '~/components/site/nav/Contents.vue'
import { User } from '~/models/users/user'
import BroadcasterImage from '~/components/_general/BroadcasterImage.vue'
import { Broadcaster } from '~/models/broadcaster'

export default Vue.extend({
  name: 'SiteNavGroupHome',
  components: { BroadcasterImage, HorizontalRule, WebcastPing, SiteNavLi },
  computed: {
    SiteNavGroup() {
      return SiteNavGroup
    },
    promotionRootUrl() {
      return promotionRootUrl
    },
    webcastsOnline(): number {
      return this.$store.getters['webcasts/liveWebcastCount']
    },
    webcastsUrl(): string {
      return webcastRootUrl
    },
    // This is a group if the user is logged in
    libraryRoot(): string | undefined {
      if (this.user) return undefined
      return libraryRootUrl
    },
    sermonsRoot(): string {
      return sermonRootUrl
    },
    signInUrl(): string {
      return siteLoginUrl(this)
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl()
    },
    speakerUrl(): string {
      return speakerRootUrl
    },
    seriesUrl(): string {
      return siteSeriesUrl()
    },
    sermonsDateUrl(): string {
      return siteSermonDateUrl()
    },
    sermonLanguageUrl(): string {
      return `${this.sermonsRoot}/languages`
    },
    sermonTopicsUrl(): string {
      return `${this.sermonsRoot}/topics`
    },
    sermonsCategoryUrl(): string {
      return `${this.sermonsRoot}/categories`
    },
    hashtagsUrl(): string {
      return `/hashtag`
    },
    user(): User | undefined {
      return this.$users.user
    },
    admin(): boolean {
      if (!this.user) return false
      return this.user.admin
    },
    scriptureRootUrl(): string {
      return scriptureRootUrl
    },
    videosUrl(): string {
      return `${this.sermonsRoot}/videos`
    },
    clipsUrl(): string {
      return `${this.sermonsRoot}/clips`
    },
    transcriptsUrl(): string {
      return `${this.sermonsRoot}/transcripts`
    },
    staffPicksUrl(): string {
      return `${this.sermonsRoot}/staff-picks`
    },
    featuredUrl(): string {
      return `${this.sermonsRoot}/featured`
    },
    myChurchID(): string {
      return this.$users.user
        ? this.$users.user.myChurchBroadcasterID
        : this.$store.getters['local/myChurchBroadcasterID']
    },
    myChurchBroadcaster(): Broadcaster | undefined {
      if (!this.myChurchID) return undefined
      const b = this.$store.getters['broadcasters/broadcaster'](this.myChurchID)
      if (!b) return undefined
      return new Broadcaster(b)
    },
    myChurchUrl(): string | undefined {
      if (!this.myChurchID) return myChurchUrl
      return siteBroadcasterUrl(this.myChurchID).slice(0, -1)
    },
  },
  watch: {
    async myChurchID() {
      await this.dispatchBroadcaster()
    },
  },
  async mounted() {
    await this.dispatchBroadcaster()
  },
  methods: {
    async dispatchBroadcaster() {
      if (!this.myChurchID) return
      await this.$store.dispatch('broadcasters/fetchBroadcaster', {
        broadcasterID: this.myChurchID,
        lite: false,
      })
    },
    navigate() {
      this.$emit('navigate')
    },
    openGroup(group: SiteNavGroup) {
      this.$emit('group', group)
    },
    goToLibrary() {
      if (this.user) {
        this.openGroup(SiteNavGroup.Library)
      } else {
        this.navigate()
      }
    },
  },
})

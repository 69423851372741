
import Vue from 'vue'
import { libraryRootUrl } from '~/assets/ts/utils/urls'
import SiteNavGroupSubnav from '~/components/site/nav/group/Subnav.vue'
import SiteNavLi from '~/components/site/nav/Li.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

export default Vue.extend({
  name: 'SiteNavGroupLibrary',
  components: {
    HorizontalRule,
    SiteNavLi,
    SiteNavGroupSubnav,
  },
  props: {
    active: {
      type: Boolean,
    },
  },
  methods: {
    libraryUrl(page = '') {
      return `${libraryRootUrl}${page}`
    },
    followingUrl(page = '') {
      return `${this.libraryUrl('following')}/${page}`
    },
    navigate() {
      this.$emit('navigate')
    },
  },
})
